@font-face {
font-family: '__satoshi_68c00e';
src: url(/_next/static/media/b1dca2a5d44fc7a4-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__satoshi_68c00e';
src: url(/_next/static/media/22539d17f3707926-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__satoshi_68c00e';
src: url(/_next/static/media/12084922609e6532-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: '__satoshi_Fallback_68c00e';src: local("Arial");ascent-override: 102.23%;descent-override: 24.29%;line-gap-override: 10.12%;size-adjust: 98.80%
}.__className_68c00e {font-family: '__satoshi_68c00e', '__satoshi_Fallback_68c00e'
}.__variable_68c00e {--font-satoshi: '__satoshi_68c00e', '__satoshi_Fallback_68c00e'
}

